import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

const BUYER_GET_HELP_ZENDESK = 'buyer-get-help-modal-zendesk' as const;
const SELLER_GET_HELP_ZENDESK = 'seller-get-help-modal' as const;

export const isGetHelpModalZendeskVariant = (): boolean =>
    getAbTestV2(BUYER_GET_HELP_ZENDESK)?.variant === 'variant';

export const isGetHelpSellerModalZendeskVariant = (): boolean =>
    getAbTestV2(SELLER_GET_HELP_ZENDESK)?.variant === 'variant';

let isTracked = false;

export const trackGetHelpZendeskVariant = (): void => {
    if (!isTracked) {
        isTracked = true;
        trackAbTestV2Variant(BUYER_GET_HELP_ZENDESK);
    }
};

export const trackGetHelpSellerZendeskVariant = (): void => {
    trackAbTestV2Variant(SELLER_GET_HELP_ZENDESK);
};
