import { graphql } from 'react-relay';
export type { footerRootQuery as footerRootQueryType } from './__generated__/footerRootQuery.graphql';

export default graphql`
    query footerRootQuery($userId: String = "", $hasUserId: Boolean!) {
        viewer {
            ...Footer_viewer
        }
    }
`;

export const initialVariables = {
    hasUserId: false,
    userId: '',
};
