import { FC, useState, useEffect } from 'react';

import { localStorage } from 'dibs-browser-storage';
import { PlacementOptions } from 'dibs-contact-1stdibs/exports/GetHelpModalPlacements';
import { AuthModalProps } from 'dibs-contact-1stdibs/exports/getHelpTypes';
import GetHelpModalLazy from 'dibs-contact-1stdibs/exports/GetHelpModalLazy';

import authModalLoader from '../utils/AuthModalLoader';
import { LOGIN, CONTACT_1STDIBS_FLOW } from '../authModal/authentication/authFlowConstants';

type Contact1stdibsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    placement: PlacementOptions;
    onChatStart?: () => void;
    onChatEnd?: () => void;
    isPersistedModal?: boolean;
};

const CONTACT_1STDIBS_PROPS = 'CONTACT_1STDIBS_MODAL_PROPS';

const getContact1stdibsModalProps = (): JsonType => {
    return localStorage.getItem(CONTACT_1STDIBS_PROPS);
};

const Contact1stdibsModal: FC<Contact1stdibsModalProps> = ({
    isOpen,
    onClose,
    placement,
    onChatStart,
    onChatEnd,
    isPersistedModal,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [modalProps, setModalProps] = useState({});

    useEffect(() => {
        const contact1stdibsModalProps = getContact1stdibsModalProps();
        if (contact1stdibsModalProps) {
            setModalProps(contact1stdibsModalProps);
            setShowModal(true);
            localStorage.setItem(CONTACT_1STDIBS_PROPS, null);
            return;
        }

        setShowModal(isOpen);
    }, [isOpen]);

    const onModalClose = (): void => {
        if (getContact1stdibsModalProps() === null) {
            localStorage.deleteItem(CONTACT_1STDIBS_PROPS);
        }
        setShowModal(false);
        setModalProps({});
        onClose();
    };

    const showAuthModal = async (props: AuthModalProps): Promise<void> => {
        setShowModal(false);
        const { user } = await authModalLoader.show({
            action: LOGIN,
            flow: props.isChatFlow ? CONTACT_1STDIBS_FLOW : '',
            ga: { label: 'support chat' },
        });

        if (user) {
            if (user.isVerifiedTrade) {
                // in some cases page reloads after trade user loggs in
                // but not all the time, and we don't know whether it will reload or not
                // so this is to make sure that page would always reload
                // so that the same treatment could be applied
                window.location.reload();
                localStorage.setItem(CONTACT_1STDIBS_PROPS, props as JsonType);
            } else {
                setModalProps(props);
                setShowModal(true);
            }
        } else {
            onModalClose();
        }
    };

    return (
        <GetHelpModalLazy
            placement={placement}
            onClose={onModalClose}
            isOpen={showModal}
            showAuthModal={showAuthModal}
            onChatStart={onChatStart}
            onChatEnd={onChatEnd}
            isPersistedModal={isPersistedModal || false}
            {...modalProps}
        />
    );
};

export default Contact1stdibsModal;
