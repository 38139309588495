/**
 * @generated SignedSource<<98d6148c3ff63271d4496c717dd57c34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Footer_viewer$data = {
  readonly footerNftLink: boolean | null;
  readonly footerQuestionsAnswersLink: boolean | null;
  readonly reviewsLink: boolean | null;
  readonly sitemapLinks: boolean | null;
  readonly wpEditorialLink: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"FooterBottomRow_viewer" | "FooterSubscribe_viewer">;
  readonly " $fragmentType": "Footer_viewer";
};
export type Footer_viewer$key = {
  readonly " $data"?: Footer_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Footer_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./FooterRefetchQuery.graphql')
    }
  },
  "name": "Footer_viewer",
  "selections": [
    {
      "alias": "footerNftLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "NFT_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"NFT_LINK\")"
    },
    {
      "alias": "footerQuestionsAnswersLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "QUESTIONS_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"QUESTIONS_LINK\")"
    },
    {
      "alias": "reviewsLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "REVIEWS_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"REVIEWS_LINK\")"
    },
    {
      "alias": "sitemapLinks",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "SITEMAP"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"SITEMAP\")"
    },
    {
      "alias": "wpEditorialLink",
      "args": [
        {
          "kind": "Literal",
          "name": "featureName",
          "value": "WP_EDITORIAL_LINK"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabledByLocale",
      "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL_LINK\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FooterSubscribe_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FooterBottomRow_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "0bae7503105a010789c62edfe7087041";

export default node;
